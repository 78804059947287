<template>
  <section class="w1314 mh100vh">
    <div class="snavi"><img class="mr10 ml10" src="@/assets/img/snavhomeicon.png"><img src="@/assets/img/snavicon.png"><span class="mr10 ml10">e-스포츠</span><img src="@/assets/img/snavicon.png"><span class="ml10 b">화면구성설명</span></div>

    <div class="mybetwrap">
      <ul class="tabs waytab">
        <li class="tab-link" :class="{'current': type == 'screendescription'}" @click="type = 'screendescription'" data-tab="tab-1"><span>화면구성설명</span></li>
        <li class="tab-link" :class="{'current': type == 'onefolderbetting'}" @click="type = 'onefolderbetting'" data-tab="tab-2"><span>단폴더 베팅방법</span></li>
        <li class="tab-link" :class="{'current': type == 'twofolderbetting'}" @click="type = 'twofolderbetting'" data-tab="tab-3"><span>다폴더 베팅방법</span></li>
      </ul>

      <div id="tab-1" class="tab-content" v-if="type == 'screendescription'">
        <div class="waytabconwrap">
          <ul class="waytabcon">
            <li><img src="@/assets/img/gameway1.jpg"></li>
            <li class="skbg">
              <h4 class="pagename">화면구성 <span class="waynum">01</span></h4>
              <ul class="waytabconin">
                <li>
                  <p><em>1.</em> 보유머니</p>
                  <span>본인의 보유머니가 표시됩니다.</span>
                </li>
                <li>
                  <p><em>2.</em> 게임종류선택</p>
                  <span>다양한 게임을 선택할 수 있습니다.</span>
                </li>
                <li>
                  <p><em>3.</em> 경기리스트</p>
                  <span>경기리스트를 확인할 수 있습니다.</span>
                </li>
                <li>
                  <p><em>4.</em> 메뉴</p>
                  <span>- 결과 : 경기결과 확인 가능</span>
                  <span>- 팀 : 팀 순위 확인 가능</span>
                  <span>- T&amp;C : 베팅규정 확인 가능</span>
                  <span>- 공지 : 게임 공지 확인 가능</span>
                </li>
                <li>
                  <p><em>5.</em> 내 베팅</p>
                  <span>상세한 베팅내역 확인 가능</span>
                </li>
                <li>
                  <p><em>6.</em> 팔레이</p>
                  <span>팔레이(다폴더) 리스트 확인 가능</span>
                </li>
              </ul>
            </li>
          </ul>

          <ul class="waytabcon">
            <li><img src="@/assets/img/gameway2.jpg"></li>
            <li class="skbg">
              <h4 class="pagename">화면구성 <span class="waynum">02</span></h4>
              <ul class="waytabconin">
                <li>
                  <p><em>1.</em> 메인(초기) 화면</p>
                  <span>메인화면으로 이동합니다.</span>
                </li>
                <li>
                  <p><em>2.</em> 베팅타입 정렬</p>
                  <span>베팅타입 별로 배당판이 정렬됩니다.</span>
                </li>
                <li>
                  <p><em>3.</em> 라이브 배당률</p>
                  <span>경기가 시작되면 라이브 배당률을 확인할 수 있습니다.</span>
                </li>
                <li>
                  <p><em>4.</em> 배당판</p>
                  <span>다양한 배당정보가 표시됩니다.</span>
                </li>
                <li>
                  <p><em>5.</em> +버튼</p>
                  <span>각팀의 좌/우 가장자리에 있는 <b class="blc">[+]</b><b class="rdc">[+]</b></span>
                </li>
                <li>
                  <p><em>6.</em> 경기영상</p>
                  <span>라이브경기는 실시간으로 경기영상을 제공하며<br>대기중인 경기는 경기정보가 표시됩니다.</span>
                </li>
                <li>
                  <p><em>7.</em> 경기상세정보</p>
                  <span>경기플레이어, 게임통계, 팀순위 등 다양한<br>경기정보를 제공합니다.</span>
                </li>
              </ul>
            </li>
          </ul>

        </div>
      </div><!-- tab-1 -->

      <div id="tab-2" class="tab-content" v-if="type == 'onefolderbetting'">
        <div class="waytabconwrap">
          <ul class="waytabcon">
            <li><img src="@/assets/img/gameway3.jpg"></li>
            <li class="skbg">
              <ul class="waytabconin">
                <li>
                  <p><em>1.</em> 종목 및 경기선택</p>
                  <span>가. ① 게임종류를 선택합니다.<br>나. ② 경기를 선택합니다. </span>
                </li>
              </ul>
            </li>
          </ul>

          <ul class="waytabcon">
            <li><img src="@/assets/img/gameway4.jpg"></li>
            <li class="skbg">
              <ul class="waytabconin">
                <li>
                  <p><em>2.</em> 배당항목 선택</p>
                  <span>원하는 ① 배당을 선택합니다.</span>
                </li>
              </ul>
            </li>
          </ul>

          <ul class="waytabcon">
            <li><img src="@/assets/img/gameway5.jpg"></li>
            <li class="skbg">
              <ul class="waytabconin">
                <li>
                  <p><em>3.</em> 베팅금액입력</p>
                  <span>가. ① 베팅금액을 입력합니다.<br>나. ② [확인] 버튼을 누르면 베팅이 완료됩니다.</span>
                </li>
                <li>
                  <h4 class="waypoint">알아두세요!</h4>
                  <span>- 1/8 최대 : 최대 한도금액을 8로 나눈 금액 베팅</span>
                  <span>- 1/4 최대 : 최대 한도금액을 4로 나눈 금액 베팅</span>
                  <span>- 1/2 최대 : 최대 한도금액을 2로 나눈 금액 베팅</span>
                </li>
              </ul>
            </li>
          </ul>

          <ul class="waytabcon">
            <li><img src="@/assets/img/gameway6.jpg"></li>
            <li class="skbg">
              <ul class="waytabconin">
                <li>
                  <p><em>4.</em> 베팅완료</p>
                  <span>베팅이 완료되면 ① 베팅내역 화면이 나타나며 상세 베팅내역을 확인할 수 있습니다.</span>
                </li>
              </ul>
            </li>
          </ul>

          <ul class="waytabcon">
            <li><img src="@/assets/img/gameway7.jpg"></li>
            <li class="skbg">
              <ul class="waytabconin">
                <li>
                  <p><em>5.</em> 베팅내역확인</p>
                  <span>가. ① [내 베팅] 메뉴를 선택합니다.<br>나. ② 베팅내역을 확인할 수 있습니다.</span>
                </li>
              </ul>
            </li>
          </ul>

        </div>
      </div><!-- tab-2 -->

      <div id="tab-3" class="tab-content" v-if="type == 'twofolderbetting'">
        <div class="waytabconwrap">
          <ul class="waytabcon">
            <li><img src="@/assets/img/gameway8.jpg"></li>
            <li class="skbg">
              <ul class="waytabconin">
                <li>
                  <p><em>1.</em> 종목 및 경기선택</p>
                  <span>가. ① 게임종류를 선택합니다.<br>나. ② 경기를 선택합니다. </span>
                </li>
              </ul>
            </li>
          </ul>

          <ul class="waytabcon">
            <li><img src="@/assets/img/gameway9.jpg"></li>
            <li class="skbg">
              <ul class="waytabconin">
                <li>
                  <p><em>2.</em> 배당항목 선택</p>
                  <span>가. ①<b class="blc">[+]</b><b class="rdc">[+]</b>버튼을 눌러서 원하는 배당을 선택합니다.<br>나. 선택한 경기(배당)가 ② 팔레이 리스트에 추가됩니다.
                    <br>- 최소 2개 이상 각각 다른 경기를 추가하세요.<br>다. 2경기 이상 추가되면 ③ [확인] 버튼을 누릅니다.</span>
                  <h4 class="waypoint">알아두세요!</h4>
                  <span>※ 각 팀의 좌/우 가장자리에있는 ①<b class="blc">[+]</b><b class="rdc">[+]</b> 버튼을 누르면 선택한 경기(배당)가<br>② 팔레이 리스트에 추가됩니다.
                    <br>※ 팔레이베팅은 최소 2개 이상 각각 다른 경기를 조합하여 베팅할 수 있습니다.<br>(같은 게임 팔레이 배팅 불가)  </span>
                </li>
              </ul>
            </li>
          </ul>

          <ul class="waytabcon">
            <li><img src="@/assets/img/gameway10.jpg"></li>
            <li class="skbg">
              <ul class="waytabconin">
                <li>
                  <p><em>3.</em> 베팅금액입력</p>
                  <span>가. ① 베팅금액을 입력합니다.<br>나. ② [확인] 버튼을 누르면 베팅이 완료됩니다.</span>
                </li>
                <li>
                  <h4 class="waypoint">알아두세요!</h4>
                  <span>- 1/8 최대 : 최대 한도금액을 8로 나눈 금액 베팅</span>
                  <span>- 1/4 최대 : 최대 한도금액을 4로 나눈 금액 베팅</span>
                  <span>- 1/2 최대 : 최대 한도금액을 2로 나눈 금액 베팅</span>
                </li>
              </ul>
            </li>
          </ul>

          <ul class="waytabcon">
            <li><img src="@/assets/img/gameway11.jpg"></li>
            <li class="skbg">
              <ul class="waytabconin">
                <li>
                  <p><em>4.</em> 베팅완료</p>
                  <span>베팅이 완료되면 ① 베팅내역 화면이 나타나며 상세 베팅내역을 확인할 수 있습니다.</span>
                </li>
              </ul>
            </li>
          </ul>

          <ul class="waytabcon">
            <li><img src="@/assets/img/gameway12.jpg"></li>
            <li class="skbg">
              <ul class="waytabconin">
                <li>
                  <p><em>5.</em> 베팅내역확인</p>
                  <span>가. ① [내 베팅] 메뉴를 선택합니다.<br>나. ② 베팅내역을 확인할 수 있습니다.</span>
                </li>
              </ul>
            </li>
          </ul>

        </div>
      </div><!-- tab-3 -->

    </div>
  </section>
</template>

<script>
export default {
  name: 'gameway',
  data () {
    return {
      type: 'screendescription'
    }
  },
  watch: {
    '$route.params.type': {
      handler () {
        this.type = this.$route.params.type
      }
    }
  }
}
</script>
<style scoped>
.skbg {background: #efefef;padding: 20px;border-radius: 16px;}
.waytab {margin-top: 30px;}
.mybetwrap  .waytab li {font-size: 14px;color: #696e72;}
.waytab li span {margin-top: 10px;font-size: 20px;font-weight: bold;padding: 10px 0;}
.waytabconwrap {border: 1px solid #ddd;border-radius: 16px;background: #fafafa;}
.waytabcon {display: flex;gap: 20px;padding: 30px;border-top: 1px solid #ddd; }
.waytabcon:first-child {border-top: 0;}
.waytabcon>li:first-child {width: 70%;}
.waytabcon>li {width: 30%;}
.waytabcon>li img {width: 100%;}
.waynum {background: #7a848f;color: #fff;font-size: 22px;border-radius: 25px;font-weight: bold;display: inline-block;padding: 7px 5px;position: absolute;left: 95px;}
.pagename {border-bottom: 1px solid #ddd;padding-bottom: 20px;}
.waytabconin {font-weight: bold;}
.waytabconin li {margin-top: 20px;}
.waytabconin p {color: #585858;font-size: 18px;}
.waytabconin em {color: #0056a6;}
.waytabconin span {color: #8c8c8c;font-size: 16px;margin-top: 5px;display: inline-block;letter-spacing: -1px;line-height: 1.2em;}
.waypoint {background: #f45400;color: #fff;display: inline-block;padding: 3px 7px;}
</style>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
